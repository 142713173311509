<template>
    <custom-table
        :title="$t('pages.system.sms.provider.title')"
        :subTitle="$t('pages.system.sms.provider.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="SystemSmsProviderFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:type="{ row: record }">
            {{ resolveDatum(types, record.type_id).name }}
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>

                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_provider" ref="providerModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="providerForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                             data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.sms.provider.cols.code') }}</label>
                                <el-form-item prop="code" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.code" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.sms.provider.cols.name') }}</label>
                                <el-form-item prop="name" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.name" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <template v-if="form.credentialsLayout.length">
                                <div class="mb-10"></div>
                                <el-divider content-position="center">{{ $t('pages.system.sms.provider.credential.title') }}</el-divider>

                                <div class="fv-row mb-7" v-for="(credential, credentialIndex) in form.credentialsLayout" :key="credentialIndex">
                                    <label class="fs-6 fw-bold mb-2">{{ credential.title }}</label>
                                    <el-form-item :prop="'credentials.' + credential.opt">
                                        <el-input v-model="form.data.credentials[credential.opt]" type="text" v-if="credential.type == 'input'"/>
                                    </el-form-item>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.system.sms.provider.cols.code"),
                    key: "code"
                },
                {
                    name: this.$t("pages.system.sms.provider.cols.name"),
                    key: "name"
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                credentialsLayout: [],
                data: {
                    credentials: {}
                }
            },
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.sms.provider.table;
        },
        types() {
            return this.$store.state.sms.provider.type.table.data;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.system.sms.provider.title"), [this.$t("menu.sms.title")]);
        this.$store.dispatch('sms/provider/get', {
            page: {},
            filterData: this.$root.getFilterWithUrl()
        });
    },
    methods: {
        newRecord() {
            this.form.updateStatus = false;
            this.form.data = {
                credentials: {},
                active: true
            };
            this.form.credentialsLayout = [];
            this.form.title = this.$t("pages.system.sms.provider.new");
            this.showModal(this.$refs.providerModal);
        },
        fetchRecord(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.system.sms.provider.edit");
            this.axios.get(this.endpoints['sms_provider'] + '/' + record.id).then(response => {
                let data = response.data.data;
                let credentials = data.credentials;

                this.form.credentialsLayout = data.credentials_layout;
                delete data.credentials_layout;

                data.credentials = {};

                if (credentials.length > 0) {
                    credentials.forEach((item) => {
                        data.credentials[item.opt] = item.val;
                    })
                }

                this.form.data = data;
                this.showModal(this.$refs.providerModal);
            });
        },
        onSubmit() {
            this.$refs.providerForm.validate((valid) => {
                if (valid) {
                    let formData = this.prepareFormData();
                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['sms_provider'] + '/' + formData.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.providerModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            error.response.data.message = this.$t('pages.system.sms.provider.responseMessages.' + error.response.data.message, error.response.data.message);

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['sms_provider'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.providerModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            error.response.data.message = this.$t('pages.system.sms.provider.responseMessages.' + error.response.data.message, error.response.data.message);

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("sms/provider/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        prepareFormData(){
            let formData = this.cloneData(this.form.data);

            let credentials = {};

            for(let index in formData.credentials){
                if(formData.credentials[index].trim().length) {
                    credentials[index] = formData.credentials[index];
                }
            }

            formData.credentials = credentials;

            return formData;
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("sms/provider/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("sms/provider/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style scoped>

</style>